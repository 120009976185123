import React from 'react'

import BackgroundSlider from 'gatsby-image-background-slider'
import {useStaticQuery, graphql } from "gatsby"

const SliderSection = ({ children }) => (
  <>
    <main>{children}</main>
    <BackgroundSlider 
      query={useStaticQuery(graphql`
        query {
          backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
            nodes {
              relativePath
              childImageSharp {
                fluid (maxWidth: 4000, quality: 100){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `)}
      initDelay={2}
      transition={4}
      duration={5}
      images={["1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg"]} 
      // style={{
      //   transform: "rotate(-2deg) scale(.9)",
      // }}           
    > 
    </BackgroundSlider>
  </>
)

export default SliderSection